import React, { useState } from "react";
import { Form, Button, Container, Row } from "react-bootstrap";
import about from '../../Assets/about.png'
import './Contact.css'

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    
    console.log(formData);
  };

  return (
    <>
    <section  className="about-section" style={{backgroundColor:"transparent",height:"100%"}}>
      <Container  className="about-section container ">
       <div className="row">
       <div  className="image col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <img src={about} className="w-100"/>
        </div>
        <div  className="form_area col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <Form onSubmit={handleSubmit} className="w-100">
            <Form.Group className="w-100">
              <Form.Label className="label" >First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter first name"
              />
            </Form.Group>

            <Form.Group className="w-100">
              <Form.Label   className="label">Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter last name"
              />
            </Form.Group>

            <Form.Group className="w-100">
              <Form.Label className="label" >Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
              />
            </Form.Group>

            <Form.Group className="w-100">
              <Form.Label className="label" >Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
            </Form.Group>

            <Form.Group className="w-100">
              <Form.Label className="label" >Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={3}
                placeholder="Enter your message"
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">
              Submit
            </Button>
          </Form>
        </div>
       </div>
      </Container>
      </section>
    </>
  );
};

export default Contact;
